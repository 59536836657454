import { put, takeEvery } from "redux-saga/effects";
import {
  setPageLoaderVisibility,
  saveAllStaffMember,
  saveStaffMemberDetails,
  getAllStaffMember,
  saveStaffInvitationData,
} from "../Actions";
import config from "./../../Config";
import apiCall from "./../../Services/api";
import { STAFF_MANAGEMENT_API } from "../../Services/apiUrls";
import * as actionTypes from "./../ActionTypes/staffManagement";
import { ToastNotifyError } from "../../Components/Toast";
import { capitalizeInputString, parseToJson } from "../../Utils/common";

function* getAllStaffMembersData(): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${STAFF_MANAGEMENT_API.getAllStaffMembers}`,
    });
    if (response && response.data?.status) {
      yield put(saveAllStaffMember(parseToJson(response.data.data)));
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Error!");
  }
}

function* getAllPermissionList(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${STAFF_MANAGEMENT_API.getAllPermissionList}`,
    });
    if (response && response.data?.status) {
      actions.callback && actions.callback(parseToJson(response.data.data));
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Error!");
  }
}

function* addStaffMember(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${STAFF_MANAGEMENT_API.addStaffMemberApi}`,
      data: actions.data,
    });
    if (response && response.data?.status) {
      yield put(getAllStaffMember());
      window.open(
        "https://api.whatsapp.com/send?phone=+91" +
          actions.data.staff_phone +
          "&text=" +
          encodeURIComponent(parseToJson(response.data.data)),
        "_blank",
      );
      actions.callback && actions.callback();
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err: any) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError(capitalizeInputString(err?.data?.message));
  }
}

function* removeStaffMember(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${STAFF_MANAGEMENT_API.removeStaffMemberApi}`,
      data: actions.data,
    });
    if (response && response.data?.status) {
      actions.callback && actions.callback();
      yield put(getAllStaffMember());
    } else {
      response.data.message && ToastNotifyError(response.data.message);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Error!");
  }
}

function* updateStaffMemberPermission(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${STAFF_MANAGEMENT_API.updateStaffPermissions}`,
      data: actions.data,
    });
    if (response && response.data?.status) {
      actions.callback && actions.callback();
      yield put(getAllStaffMember());
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Error!");
  }
}

function* getStaffMemberDetails(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${STAFF_MANAGEMENT_API.getStaffMemberDetails}`, // /${actions.data.storeId}
      parseToJson: true,
    });
    if (response && response.data?.status) {
      const parsedData = response.data.data;
      yield put(saveStaffMemberDetails(parsedData));
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Error!");
  }
}

function* checkStaffExistence(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${STAFF_MANAGEMENT_API.checkStaffExistence}`,
      data: actions.data,
    });
    if (response && response.data?.status) {
      const parsedData = parseToJson(response.data.data);
      parsedData && actions.callback && actions.callback();
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err: any) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError(capitalizeInputString(err?.data?.message));
  }
}

function* sendStaffInvitation(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${STAFF_MANAGEMENT_API.sendStaffInvitation}`,
      data: actions.data,
    });
    if (response && response.data?.status) {
      if (actions.data.invite_mode) {
        window.open(
          "https://api.whatsapp.com/send?phone=+91" +
            actions.data.staff_phone +
            "&text=" +
            encodeURIComponent(parseToJson(response.data.data)),
          "_blank",
        );
      }
      actions.callback && actions.callback();
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err: any) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError(capitalizeInputString(err?.data?.message));
  }
}

function* updateUserInvitation(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${STAFF_MANAGEMENT_API.updateUserinvitation}`,
      data: actions.data,
    });
    if (response && response.data?.status) {
      const staffData = {
        is_invitation_available: false,
        staff_invitation: {},
      };
      yield put(saveStaffInvitationData(staffData));
      actions.callback && actions.callback(parseToJson(response.data.data));
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    ToastNotifyError(err?.data?.message);
    yield put(setPageLoaderVisibility(false));
  }
}

function* checkStaffInvite(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${STAFF_MANAGEMENT_API.checkStaffInvite}`,
    });
    if (response && response.data?.status) {
      const parsedData = parseToJson(response.data.data);
      const staffData = {
        is_invitation_available: parsedData.is_invitation_available,
        staff_invitation: parsedData.staff_invitation,
      };
      yield put(saveStaffInvitationData(staffData));
      actions.callback && actions.callback(parsedData);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Error!");
  }
}

function* getRequestPermission(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${STAFF_MANAGEMENT_API.getRequestPermissionApi}${actions.data.permission}`,
    });
    if (response && response.data?.status) {
      const parsedData = parseToJson(response.data.data);
      window.open(
        "https://api.whatsapp.com/send?phone=+91" +
          actions.data.staff_phone +
          "&text=" +
          encodeURIComponent(parsedData),
        "_blank",
      );
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Error!");
  }
}

export default function* root() {
  yield takeEvery(actionTypes.GET_ALL_STAFF_MEMBERS_ACTION, getAllStaffMembersData);
  yield takeEvery(actionTypes.GET_ALL_PERMISSION_LIST, getAllPermissionList);
  yield takeEvery(actionTypes.ADD_STAFF_MEMBER_ACTION, addStaffMember);
  yield takeEvery(actionTypes.REMOVE_STAFF_MEMBER_ACTION, removeStaffMember);
  yield takeEvery(actionTypes.UPDATE_STAFF_MEMBER_PERMISSION_ACTION, updateStaffMemberPermission);
  yield takeEvery(actionTypes.GET_STAFF_MEMBER_DETAILS, getStaffMemberDetails);
  yield takeEvery(actionTypes.CHECK_STAFF_MEMBER_EXISTENCE_ACTION, checkStaffExistence);
  yield takeEvery(actionTypes.SEND_STAFF_INVITATION_ACTION, sendStaffInvitation);
  yield takeEvery(actionTypes.UPDATE_USER_INVITATION_ACTION, updateUserInvitation);
  yield takeEvery(actionTypes.CHECK_STAFF_INVITE_EXISTENCE, checkStaffInvite);
  yield takeEvery(actionTypes.GET_REQUEST_PERMISSION_ACTION, getRequestPermission);
}
