import { SETTING_TYPE_KEY } from "@/Containers/Settings/utils/constants";

export const ECOMM_ELITE_DISPLAY_FEATURE = [
  {
    name: "GST Billing Feature",
    logo: "https://cdn.dotpe.in/longtail/themes/76/r4o70hbZ.png",
  },
  {
    name: "Customer Reviews & Ratings",
    logo: "https://cdn.dotpe.in/longtail/themes/76/wUqwycxV.png",
  },
  {
    name: "Advance Custom SEO",
    logo: "https://cdn.dotpe.in/longtail/themes/76/Xw5s5e4K.png",
  },
  {
    name: "Partial Payment",
    logo: "https://cdn.dotpe.in/longtail/themes/76/rhnSFjin.png",
  },
  {
    name: "Business to Business (B2B)",
    logo: "https://cdn.dotpe.in/longtail/themes/76/kujxGZZs.png",
  },
];

export const TESTIMONIALS = [
  {
    text: "“Dotpe is the tool I trust for my online business. They make everything so easy to use. All features I need at a single place.”",
    author: "Makaam Electronics, Hyderabad",
  },
  {
    text: "“This is extremely cost effective. Other people charge a lot for all the features they give. This is too good.”",
    author: "New Era 21 books & publication, Pune",
  },
  {
    text: "“I get to focus on my business, while Dotpe takes care of the e-commerce part of my store. Their product & solutions are perfect.”",
    author: "Rifiaz Fashion Store, Delhi NCR",
  },
];

export const SUBSCRIPTION_FLAG = {
  NOT_SUBSCRIBED: 0,
  SUBSCRIBED: 1,
  OLD_USER: 2,
  EXPIRED: 3,
} as const;

export enum SUBSCRIPTION_ID {
  CREDIT_ADDITION_ID = 0, // Adding Credits is represented by Subscription Id = 0
  THEME_SUBSCRIPTION_ID = 1,
  DOMAIN_SUBSCRIPTION_ID = 2,
  DOMAIN_EXTERNAL_SUBSCRIPTION_ID = 3,
  GOOGLEAD_SUBSCRIPTION_ID = 4,
  SUBSCRIPTION_SUITE_SUBSCRIPTION_ID = 5,
  ADDITIONAL_PAGE_SUBSCRIPTION_ID = 6,
  YOUTUBE_LINK_SUBSCRIPTION_ID = 7,
  WORKSPACE_SUBSCRIPTION_ID = 8,
  MULTIBANNER_SUBSCRIPTION_ID = 9,
  SUBSCRIPTION_ID_CUSTOMER_REVIEWS = 10,
  CAMPAIGN_SUBSCRIPTION_ID = 11,
  SUBSCRIPTION_ID_GST = 12,
  SUBSCRIPTION_ID_OTHER_CHARGES = 13, // Charged by Rajesh/Anupendra
  SUBSCRIPTION_ID_WABA = 14,
  SUBSCRIPTION_ID_BRAND_WEBSITE = 15,
  SUBSCRIPTION_ID_SOCIAL_MEDIA_TEMPLATES = 16,
  SUBSCRIPTION_ID_ANDROID_APP = 17,
  SUBSCRIPTION_ID_IOS_APP = 18,
  SUBSCRIPTION_ID_SCHEDULAR = 19,
  SUBSCRIPTION_ID_NEW_ECOMMERCE = 20,
}

export const ECOMM_ELITE = "ecomm_elite" as const;
export const ECOMM_PLUS = "ecomm_plus" as const;
export const ECOMM_TO_ECOMM_ELITE = "ecomm_to_ecomm_elite" as const;
export const ECOMM_TO_ECOMM_PLUS = "ecomm_to_ecomm_plus" as const;
export const ECOMM_PLUS_TO_ECOMM_ELITE = "ecomm_plus_to_ecomm_elite" as const;
export const ECOMM_PLUS_TO_ECOMM_PLUS_RENEW = "ecomm_plus_to_ecomm_plus" as const;
export const ECOMM_TO_ECOMM_RENEW = "ecomm_to_ecomm_renew" as const;
export const ECOMM = "ecomm" as const;
export const THEME_PLUS = "theme_plus" as const;
export const B2B_ENABLED = "b2b_enabled" as const;

/** These are newer subscriptions introduced as part of product packaging ( DD- 5564) */
export const ECOMM_NEW_SUBSCRIPTIONS = {
  ESSENTIAL: "esential",
  PRO: "pro",
  ELITE: "elite",
} as const;

export const FEATURE_LOCKS_INITIAL_VALUE = {
  abandonedCart: {
    isVisible: false,
    isLocked: false,
  },
  additionalPage: {
    isVisible: true,
    isLocked: true,
  },
  bulkUpload: {
    isVisible: true,
    isLocked: true,
  },
  collections: {
    isVisible: true,
    isLocked: true,
  },
  couponsVouchers: {
    isVisible: true,
    isLocked: true,
  },
  crm: {
    isVisible: true,
    isLocked: true,
  },
  crr: {
    isVisible: true,
    isLocked: true,
  },
  customFields: {
    isVisible: true,
    isLocked: true,
  },
  fbPixel: {
    isVisible: true,
    isLocked: true,
  },
  googleAnalytics: {
    isVisible: true,
    isLocked: true,
  },
  googleShopping: {
    isVisible: true,
    isLocked: true,
  },
  gst: {
    isVisible: true,
    isLocked: true,
  },
  gtm: {
    isVisible: true,
    isLocked: true,
  },
  installApp: {
    isVisible: true,
    isLocked: true,
  },
  inventory: {
    isVisible: true,
    isLocked: true,
  },
  leadGen: {
    isVisible: true,
    isLocked: true,
  },
  manageStaff: {
    isVisible: true,
    isLocked: true,
  },
  multibanner: {
    // (components -> UploadBannersModal -> index.tsx)
    isVisible: true,
    isLocked: true,
  },
  multivariants: {
    isVisible: true,
    isLocked: true,
  },
  nationalDelivery: {
    isVisible: true,
    isLocked: true,
  },
  [SETTING_TYPE_KEY.STORE_PICK_UP_ADDRESSES]: {
    isVisible: true,
    isLocked: false,
  },
  onlinePaymentModes: {
    isVisible: true,
    isLocked: true,
  },
  partialPayment: {
    isVisible: false,
    isLocked: true,
  },
  pincodeBasedDelivery: {
    isVisible: true,
    isLocked: true,
  },
  prepaidOrders: {
    isVisible: true,
    isLocked: true,
  },
  productAdvancedDesc: {
    isVisible: true,
    isLocked: true,
  },
  productFiltering: {
    isVisible: true,
    isLocked: true,
  },
  productSorting: {
    isVisible: true,
    isLocked: true,
  },
  productTags: {
    isVisible: true,
    isLocked: true,
  },
  productVideo: {
    isVisible: true,
    isLocked: true,
  },
  sendPaymentLink: {
    isVisible: true,
    isLocked: true,
  },
  serviceBasedCustomization: {
    isVisible: true,
    isLocked: true,
  },
  seo: {
    isVisible: true,
    isLocked: true,
  },
  singlevariant: {
    isVisible: true,
    isLocked: true,
  },
  smsMarketing: {
    isVisible: true,
    isLocked: true,
  },
  storeAnalytics: {
    isVisible: true,
    isLocked: true,
  },
  hideOutOfStock: {
    isVisible: true,
    isLocked: true,
  },
};

/**
 * subscriptionType :1 for essential , 2 for pro and 3 for elite
 */

export const FEATURE_DATA = [
  { name: "National Delivery", subscriptiontype: 1 },
  { name: "Unlimited Premium Themes", subscriptiontype: 1 },
  { name: "Prepaid Orders", subscriptiontype: 1 },
  { name: "Payment Options And EPOS", subscriptiontype: 1 },
  { name: "Coupons And Vouchers", subscriptiontype: 1 },
  { name: "Staff Login & Order Reports", subscriptiontype: 1 },
  { name: "Google Shopping", subscriptiontype: 1 },
  { name: "Marketing Integrations", subscriptiontype: 1 },
  { name: "Complete Inventory Management", subscriptiontype: 2 },
  { name: "Abandoned Cart", subscriptiontype: 2 },
  { name: "End To End Customer Relationship Management", subscriptiontype: 2 },
  { name: "GST Billing Feature", subscriptiontype: 3 },
  { name: "Customer Reviews & Ratings", subscriptiontype: 3 },
  { name: "Multi Variants (Upto 3 Variants", subscriptiontype: 3 },
  { name: "Add Custom Filters (Upto 20)", subscriptiontype: 3 },
  { name: "Sorting Leads", subscriptiontype: 3 },
];

export const ECCOM_ELITE = "ecomm_elite";

export const UPGRADE_TO_VALUE = {
  [ECOMM]: 0,
  [ECOMM_TO_ECOMM_PLUS]: 1,
  [ECOMM_PLUS]: 2,
  [ECOMM_TO_ECOMM_RENEW]: 3,
  [ECOMM_PLUS_TO_ECOMM_PLUS_RENEW]: 5,
  [ECOMM_TO_ECOMM_ELITE]: 8,
  [ECOMM_PLUS_TO_ECOMM_ELITE]: 9,
  [ECOMM_ELITE]: 7,
};

export const SUBSCRIPTION_TYPE_VAL = {
  essential: 1,
  pro: 2,
  elite: 3,
};

export const SUBSCRIPTION_TYPES = {
  ADVANCED_CUSTOM_SEO: "advancedCustomSeo",
};
export const GET_PLAN_TYPE = {
  [ECOMM]: {
    type: "Essential",
    description: "Ideal for new businesses and merchants",
  },
  [ECOMM_PLUS]: {
    type: "Ecommerce",
    description: "Ideal for established brands to grow their business.",
  },
  [ECOMM_ELITE]: {
    type: "Ecommerce",
    description: "Ideal for established brands to grow their business.",
  },
};

export const MRP = "mrp";
export const NET_PRICE = "net_price";

export const PAYMENT_OPTIONS_KEY = "paymentOptions";
export const ECOMM_SUBSCRIPTION_ID = 5;
