export const SETTINGS_ROUTE = "/settings";
export const EDIT_STORE_DETAILS_ROUTE = "/edit-store-details";
export const STORE_CONTROLS_ROUTE = "/controls";
export const PAYMENT_OPTIONS_ROUTES = "/payment-options";
export const APP_SETTINGS_ROUTE = "/app-settings";
export const THEME_HISTORY_ROUTE = "/themes-history";
export const CUSTOM_DOMAIN_ROUTE = "/domain-explore";
export const MARKETING_ROUTE = "/marketing";
export const COUPONS_LIST_ROUTE = "/coupons-list";
export const CREATE_COUPON_ROUTE = "/create-coupon";
export const EXPLORE_THEME_ROUTE = "/themes-explore";
export const THEME_CATEGORY = "/themes/category/";
export const LOGIN_ROUTE = "/login";
export const MY_PAYMENT_ROUTE = "/my-payments";
export const SUBMIT_SUGGESTION_ROUTE = "/submit-suggestion";
export const MY_INVOICES_ROUTE = "/my-invoices";
export const CONTACT_US = "/contact-us";
export const ORDER_DETAIL_PAGE_ROUTE = "/order-detail";
export const FACEBOOK_PIXEL_ROUTE = "/marketing/analytics/facebook_pixel";
export const GOOGLE_ANALYTICS_ROUTE = "/marketing/analytics/google_analytics";
export const GOOGLE_TAG_MANAGER_ROUTE = "/marketing/analytics/g_tag";
export const SUBSCRIPTION = "/subscription";
export const EMAIL_VERIFICATION = "/email-verification";
export const SUBSCRIPTION_RENEWAL = "/subscription?renewal=1";
export const SUBSCRIPTION_DETAILS = "/subscription-details";
export const BUY_SUBSCRIPTION = "/ecommerce-subscription";
export const DELIVERY_PARTNER_ROUTE = "/delivery-partner";
export const RETURN_REFUND_POLICY_ROUTE = "/return-refund-policy";
export const ORDER_CANCELLATION_REFUND_ROUTE = "/order-cancellation-refund";
export const COD_CHARGES_ROUTE = "/cash-on-delivery-charges";
export const CUSTOMER_ADDRESS_FIELD = "/customer-address-field";
export const PAGE_BUILDER_ROUTE = "/page-builder";
export const KYC_COMPLETION_ROUTE = "/setup-delivery";
export const PAYMENT = "/payment";
export const ADDITIONAL_PAGES_ROUTE = "/additional-pages";
export const EDIT_PAGE_ROUTE = "/edit-page";
export const CUSTOM_PAGES_ROUTE = "/additional-pages/editor";
export const STAFF_PAGE_ROUTE = "/staff-details";
export const WORKSPACE_PREPURCHASE_ROUTE = "/workspace/purchase";
export const WORKSPACE_POSTPURCHASE_ROUTE = "/workspace";
export const WORKSPACE_ACCOUNT_SETUP = "/workspace/setup";
export const WORKSPACE_ACCOUNT_INITIAL_SETUP = "/workspace/initial-setup";
export const CRM_ROUTE = "/crm";
export const ORDERS_PAGE_ROUTE = "/orders";
export const CARTS_PAGE_ROUTE = "/leads";
export const CART_PRODUCT_DETAILS = "/cart-product-details";
export const FACEBOOK_BUSINESS_SUITE_ROUTE = "/marketing/facebook-business-suite";
export const CREDIT_ENGINE_ROUTE = "/my-credits";
export const LEAD_GENERATION_ROUTE = "/lead-generation";
export const SETTINGS_CONTROL_ROUTE = "/settings/controls";
export const SETTINGS_STORE_POLICY = "/store-policy";
export const STORE_ANALYTICS_ROUTE = "/analytics/store-analytics";
export const GOOGLE_SHOPPING_ROUTE = "/google-shopping";
export const FACEBOOK_ADS = "/facebook-ads";
export const SEO_TOOLS = "/seo-tools";
export const DOMAIN_HISTORY = "/domain-history";
export const DOMAIN_SEARCH = "/domain-search";
export const SMS_MARKETING_ROUTE = "/marketing/sms-marketing-dashboard";
export const CREATE_CAMPAIGN_ROUTE = "/create-campaign";
export const SMS_CAMPAIGN_PAYMENT_SUCCESS_ROUTE = "/sms-campaign-payment-success";
export const adStep = (adId: any, step: number): string => `/marketing/ad/${adId}/step/${step}`;
export const adHistory = (activeTab: number): string => `/marketing/ads-history/${activeTab}`;
export const MOBILE_ONBOARDING = "/mobile-onboarding";
export const LOGIN_PAGE = "/login";
export const CATALOG_PRODUCT = "/catalog";
export const COLLECTIONS_LIST = "/collections";
export const ADD_COLLECTION = "/collections/add";
export const EDIT_COLLECTION = "/collections/:id";
export const COLLECTION_CATEGORY_LIST = "/collection/category/:collectionId/:categoryId";
export const collectionCategoryListRoute = (collectionId: number, categoryId: number): string =>
  `/collection/category/${collectionId}/${categoryId}`;
export const CUSTOM_DELIVERY_SETUP = "/custom-delivery/setup";
export const CUSTOM_DELIVERY_DETAILS = "/custom-delivery/details";
export const CUSTOM_ZONES_SETUP = "/custom-delivery/setup-zones";
export const WEB_EDITOR_ROUTE = "/web-editor";
export const GST_BILLING_ROUTE = "/gst-billing";
export const CUSTOMER_REVIEW_RATINGS_ROUTE = "/customer-review-ratings";
export const INVOICE_DISCLAIMER_POLICY_ROUTE = "/invoice-disclaimer-policy";
export const SOCIAL_MEDIA_PROFILE_ROUTE = "/add-social-media";
export const WHATSAPP_BUSINESS_DEMO = "/marketing/whatsapp-business-marketing";
export const MARKETING_COUPONS_LIST = "/marketing/coupons-list";
export const OUT_OF_STOCK_ROUTE = "/out-of-stock";
export const SETUP_B2B_ROUTE = "/setup-b2b";
export const GOOGLE_SHOPPING_V2_LANDING_PAGE = "/GoogleShoppingV2/LandingPage";
export const GOOGLE_SHOPPING_V2_MERCHANT_FORM = "/GoogleShoppingV2/MerchantForm";
export const GOOGLE_SHOPPING_V2_SUCCESS_PAGE = "/GoogleShoppingV2/SuccessPage";
export const GOOGLE_SHOPPING_V2_DASHBOARD = "/GoogleShoppingV2/Dashboard";
export const BULK_EDIT = "/bulkedit";
export const WABA_PAYMENT_OLD_WEBVIEW_ROUTE = "/waba-payment-initiate";
export const SUBSCRIPTION_PAYMENT_USING_COUPONS_ROUTE = "/subscription-payment";
export const BRAND_WEBSITE_BUSINESS_CATEGORIES = "/brand-website-categories";
export const BRAND_WEBSITE_CATEGORY_THEMES = "/brand-website-themes";
export const BRAND_WEBSITE_THEME_PREVIEW = "/brand-website-theme-preview";
export const BRAND_WEBSITE_THEME_DEMO = "/brand-website-demo";
export const BRAND_WEBSITE_PAYMENT_SUCCESS = "/brand-website-payment-success";
export const BRAND_WEBSITE_RENEWAL_PAGE = "/brand-website-renewal";
export const CFE_BRAND_WEBSITE_THEME_DEMO = "/brand-website-theme-demo"; // being used to render CFE website in iframe
export const DOMAIN_CART_PAGE = "/domain-cart";
export const DOMAIN_SEARCH_PAGE = "/domain-open-search";
export const DOMAIN_PURCHASE_SUCCESS = "/domain-purchase-success";
export const PREMIUM_PAGE = "/premium-page";
export const ECOMM_PRICING_PAGE = "/ecomm-pricing-page";
export const APP_UTILITIES = "/app-utilities";
export const SUBSCRIPTION_STATUS_DETAILS = "/subscription-status-details";
export const DOMAIN_LANDING_PAGE = "/domain-landing";
export const DOMAIN_LANDING_PAGE_PREFIX = "/domain-landing/:path";
export const EXIT_GATING_HOMEPAGE = "/exit-gating";
export const EXIT_GATING_EDITOR = "/exit-gating-editor";
export const DOMAIN_REQUEST_TO_CALLBACK = "/request-to-callback";
export const MY_INVOICES = "/my-invoices";
export const SHIPPING_LABEL_GENERATOR = "/shipping-label-generator";
export const OLD_SETTINGS_ROUTE = {
  EDIT_PROFILE: "/settings/edit-store-details",
  APP_SETTINGS: "/settings/app-settings",
  SOCIAL_MEDIA_URL: "/settings/add-social-media",
};
export const THEME_EXPLORE = "/themes-explore";
export const TITAN_DASHBOARD = "/workspace";
export const REDIRECTION_CONNECTOR = "/redirection-connector";
export const DELETE_STORE = "/delete-store";

export const WHATSAPP_MARKETING_WEBVIEw = "/webview/whatsapp-business-marketing";
export const WABA_RENEWAL_WEBVIEW = "/whatsapp-business-renew";
export const BUSINESS_CARDS_ROUTE = "/business-cards";
export const ECOMM_PURCHASE_INITIATE = "/ecomm-purchase-initiate";
export const QR_ROUTE = "/qr";

export const HELP_SECTION_URL = "https://digitalshowroom.in/help-section/";

export const BUSINESS_LOANS_REDIRECTION_URL =
  "https://www.dotpecappitals.in/?utm_source=internal-partnership&utm_medium=ds&utm_campaign=v1";

// this would be a common route for payment
export const MOBILE_PAYMENT_ROUTE_WEBVIEW = "/payment-mobile";
