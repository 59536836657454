import React from "react";
import { SETTING_PAGE_SUBROUTES } from "../../../Constants/setting";
import {
  ContactUsSVG,
  ControlSVG,
  CustomerSVG,
  DeliverySVG,
  HelpCenterSVG,
  IdeasSVG,
  OrderSVG,
  OtherSVG,
  PaymentSVG,
  PolicySVG,
  ProfileSVG,
  StaffSVG,
} from "./SvgExport";

type SidebarSettingListDataType = SideBarSettingListType[];
interface SideBarSettingListType {
  id: string;
  type: string;
  option: Option[];
}
interface Option {
  logo: (color: string) => React.JSX.Element;
  settingType: string;
  subSettings: any;
  isActive: boolean;
  route: string;
}

export const SETTING_TYPE_KEY = {
  STORE_LOGO: "store-logo",
  DISPLAY_NUMBER: "display-number",
  REGISTERED_NUMBER: "registered-number",
  EMAIL: "email",
  DESCRIPTION: "description",
  ADDRESS: "address",
  BUSINESS_TYPE: "business-type",
  SOCIAL_MEDIA: "social-media",
  KYC: "KYC",
  BANK_DETAIL: "bank-details",
  MY_INVOICE: "my-invoice",
  MIN_ORDER_DELIVERY: "minOrderDelivery",
  DELIVERY_CHARGE: "deliveryCharge",
  CASH_ON_DELIVERY: "codCharges",
  NATIONAL_DELIVERY: "nationalDelivery",
  STORE_PICK_UP_ADDRESSES: "storePickupAddresses",
  PAYMENT_MODE: "onlinePaymentModes",
  GST: "gst",
  MY_PAYMENTS: "my-payments",
  ORDER_TYPE: "prepaidOrders",
  CUSTOMER_ADDRESS_FIELD: "customerAddressFields",
  SERVICE_CUSTOMER_ADDRESS: "serviceCustomerAddress",
  B2BCUSTOMER_DETAIL: "b2bCustomerDetail",
  STORE_LOGIN: "storeLogin",
  MEMBER_ONLY_LOGIN: "memberOnlyLogin",
  STAFF_ACCOUNT: "manageStaff",
  RETURN_REFUND_POLICY: "returnRefundPolicy",
  INVOICE_DISCLAIMER_POLICY: "invoiceDisclaimerPolicy",
  CANCELATION_REFUND_POLICY: "cancelAndRefund",
  SHIPMENT_PAYMENT_POLICY: "shippingPaymentPolicy",
  TERMS_CONDITIONS: "termsAndCondition",
  PRIVACY_POLICY: "privacyPolicy",
  ABOUT_US: "aboutUs",
  LOGOUT: "logOut",
  DELETE_ACCOUNT: "deleteAccount",
  LOGOUT_FROM_ALL_DEVICES: "logoutFromAllDevices",
  CUSTOM_DOMAIN: "custom-domain",
  PREMIUM: "premium",
  LEAD: "leadGeneration",
  CHANGE_THEME: "changeTheme",
  EDIT_BUTTON_TEXT: "editButtonText",
  LEAD_GENERATION: "leadGen",
  CUSTOMER_REVIEW_RATINGS: "customer-review-ratings",
  VISITOR_LOGIN: "visitorLogin",
};

export const SETTINGS_LIST_DATA: SidebarSettingListDataType = [
  {
    id: "store",
    type: "STORE",
    option: [
      {
        logo: (color) => ControlSVG({ color: color }),
        settingType: "Controls",
        subSettings: null,
        isActive: true,
        route: SETTING_PAGE_SUBROUTES.CONTROLS,
      },
      {
        logo: (color) => ProfileSVG({ color: color }),
        settingType: "Profile",
        subSettings: null,
        isActive: false,
        route: SETTING_PAGE_SUBROUTES.PROFILE,
      },
    ],
  },
  {
    id: "service",
    type: "SERVICE",
    option: [
      {
        logo: (color) => DeliverySVG({ color: color }),
        settingType: "Delivery",
        subSettings: null,
        isActive: false,
        route: SETTING_PAGE_SUBROUTES.DELIVERY,
      },
      {
        logo: (color) => PaymentSVG({ color: color }),
        settingType: "Payments",
        subSettings: null,
        isActive: false,
        route: SETTING_PAGE_SUBROUTES.PAYMENTS,
      },
      {
        logo: (color) => OrderSVG({ color: color }),
        settingType: "Orders",
        subSettings: null,
        isActive: false,
        route: SETTING_PAGE_SUBROUTES.ORDERS,
      },
      {
        logo: (color) => OrderSVG({ color: color }),
        settingType: "Quotes",
        subSettings: null,
        isActive: false,
        route: SETTING_PAGE_SUBROUTES.QUOTES,
      },
    ],
  },
  {
    id: "general",
    type: "GENERAL",
    option: [
      {
        logo: (color) => CustomerSVG({ color: color }),
        settingType: "Customer",
        subSettings: null,
        isActive: false,
        route: SETTING_PAGE_SUBROUTES.CUSTOMER,
      },
      {
        logo: (color) => StaffSVG({ color: color }),
        settingType: "Staff",
        subSettings: null,
        isActive: false,
        route: SETTING_PAGE_SUBROUTES.STAFF,
      },
      {
        logo: (color) => PolicySVG({ color: color }),
        settingType: "Store policies",
        subSettings: null,
        isActive: false,
        route: SETTING_PAGE_SUBROUTES.STORE_POLICIES,
      },
    ],
  },
  {
    id: "others",
    type: "",
    option: [
      {
        logo: (color) => HelpCenterSVG({ color: color }),
        settingType: "Help center",
        subSettings: null,
        isActive: false,
        route: SETTING_PAGE_SUBROUTES.HELP_CENTER,
      },
      {
        logo: (color) => IdeasSVG({ color: color }),
        settingType: "Suggest ideas",
        subSettings: null,
        isActive: false,
        route: SETTING_PAGE_SUBROUTES.SUGGEST_IDEA,
      },
      {
        logo: (color) => ContactUsSVG({ color: color }),
        settingType: "Contact us",
        subSettings: null,
        isActive: false,
        route: SETTING_PAGE_SUBROUTES.CONTACT_US,
      },
      {
        logo: (color) => OtherSVG({ color: color }),
        settingType: "Other",
        subSettings: null,
        isActive: false,
        route: SETTING_PAGE_SUBROUTES.OTHERS,
      },
    ],
  },
];

export const PROFILE_PAGE_SETTINGS = [
  {
    type: "Profile",
    settingList: [
      {
        key: "store-logo",
        featureName: "",
        toShowBorder: true,
      },
      {
        key: "registered-number",
        featureName: "",
        toShowBorder: true,
      },
      {
        key: "display-number",
        featureName: "",
        toShowBorder: true,
      },
      {
        key: "email",
        featureName: "Email",
        toShowBorder: true,
      },
      {
        key: "description",
        featureName: "Store description",
        toShowBorder: true,
      },
      {
        key: "address",
        featureName: "Store address",
        toShowBorder: true,
      },
      {
        key: "business-type",
        featureName: "Business type",
        toShowBorder: true,
      },
      {
        key: "social-media",
        featureName: "Social media",
        toShowBorder: false,
      },
    ],
  },
  {
    type: "Billing & verification",
    settingList: [
      {
        key: "KYC",
        featureName: "KYC",
        toShowBorder: true,
      },
      {
        key: "bank-details",
        featureName: "Bank account",
        toShowBorder: true,
      },
      {
        key: "my-invoice",
        featureName: "My invoices",
        toShowBorder: false,
      },
    ],
  },
];

export const DELIVERY_PAGE_SETTINGS = [
  {
    type: "Delivery charges",
    settingList: [
      {
        key: "minOrderDelivery",
        featureName: "Minimum order value for delivery",
        mainText: "Set a minimum value for orders to be eligible for delivery",
        toShowBorder: true,
      },
      {
        key: "deliveryCharge",
        featureName: "Delivery charge",
        mainText: "Set charges for delivery",
        toShowBorder: true,
      },
      {
        key: "codCharges",
        featureName: "Cash on delivery charges",
        mainText: "Set extra charges for cash on delivery",
        toShowBorder: false,
      },
    ],
  },
  {
    type: "Shipping",
    settingList: [
      {
        key: "nationalDelivery",
        featureName: "Deliver using Delivery Partners",
        mainText: "Set up delivery using our delivery partners",
        toShowBorder: true,
      },
      {
        key: SETTING_TYPE_KEY.STORE_PICK_UP_ADDRESSES,
        featureName: "Pickup addresses",
        mainText: "Set up & manage your pickup addressses",
        toShowBorder: false,
      },
    ],
  },
];

export const PAYMENT_PAGE_SETTINGS = [
  {
    type: "Payment modes",
    settingList: [
      {
        key: "onlinePaymentModes",
        featureName: "Online payment modes",
        mainText: "Set how you want to accept payments online",
        toShowBorder: false,
      },
    ],
  },
  {
    type: "Invoices",
    settingList: [
      {
        key: "gst",
        featureName: "GST billing",
        mainText: "Generate GST invoices for customer orders",
        toShowBorder: true,
      },
      {
        key: "my-payments",
        featureName: "Customer payment invoices",
        mainText: "View customer payment and settlements",
        toShowBorder: true,
      },
    ],
  },
];

export const ORDER_PAGE_SETTINGS = [
  {
    type: "",
    settingList: [
      {
        key: "prepaidOrders",
        featureName: "Order type",
        mainText: "Set how you want to accept payments online",
        toShowBorder: true,
      },
      {
        key: "customerAddressFields",
        featureName: "Customer address fields",
        mainText: "Select the information you want to collect from customers when they place an order",
        toShowBorder: false,
      },
    ],
  },
  {
    type: "",
    settingList: [
      {
        key: "prepaidOrders",
        featureName: "Order type",
        mainText: "Set how you want to accept payments online",
        toShowBorder: true,
      },
      {
        key: "serviceCustomerAddress",
        featureName: "Customer address",
        mainText: "Check it if you need customer's address while checkout",
        toShowBorder: false,
      },
    ],
  },
  {
    type: "",
    settingList: [
      {
        key: "b2bCustomerDetail",
        featureName: "Customer details form",
        mainText: "Select the fields you want customers to fill while requesting a quote",
        toShowBorder: false,
      },
    ],
  },
];

export const CUSTOMER_PAGE_SETTINGS = [
  {
    type: "",
    settingList: [
      {
        key: "storeLogin",
        featureName: "Store login",
        mainText: "Manage how your customers can login to the store",
        toShowBorder: true,
      },
      {
        key: "memberOnlyLogin",
        featureName: "Invite only",
        mainText: "Limit the access to your website to limited customers",
        toShowBorder: true,
      },
      {
        key: "leadGen",
        featureName: "Lead generation",
        mainText: "Get customer leads by activating subscribe now popup",
        toShowBorder: false,
      },
    ],
  },
];

export const STAFF_PAGE_SETTINGS = [
  {
    type: "",
    settingList: [
      {
        key: "manageStaff",
        featureName: "Manage staff accounts",
        mainText: "Your account type - Store Owner",
        toShowBorder: false,
      },
    ],
  },
];

export const B2C_STORE_POLICY_SETTING = [
  {
    type: "Order policies",
    settingList: [
      {
        key: "returnRefundPolicy",
        featureName: "Return & refund policy",
        mainText: "Set a policy for order returns & refunds",
        toShowBorder: true,
      },
      {
        key: "cancelAndRefund",
        featureName: "Order cancellation & rejection policy",
        mainText: "Set a policy for order cancellations & rejections",
        toShowBorder: true,
      },
      {
        key: "shippingPaymentPolicy",
        featureName: "Shipping & payment policy",
        mainText: "Set a policy for order shipping & payment",
        toShowBorder: true,
      },
      {
        key: "invoiceDisclaimerPolicy",
        featureName: "Invoice Disclaimer Policy",
        mainText: "Set your own customized disclaimer for your GST invoice",
        toShowBorder: true,
      },
    ],
  },
  {
    type: "General policies",
    settingList: [
      {
        key: "termsAndCondition",
        featureName: "Terms & conditions",
        mainText: "",
        toShowBorder: true,
      },
      {
        key: "privacyPolicy",
        featureName: "Privacy policy",
        mainText: "",
        toShowBorder: false,
      },
    ],
  },
];
export const B2B_STORE_POLICY_SETTING = [
  {
    type: "Order policies",
    settingList: [
      {
        key: "cancelAndRefund",
        featureName: "Request cancellation & rejection policy",
        mainText: "Set a policy for order cancellations & rejections",
        toShowBorder: true,
      },
      {
        key: "shippingPaymentPolicy",
        featureName: "Shipping & payment policy",
        mainText: "Set a policy for order shipping & payment",
        toShowBorder: true,
      },
      {
        key: "invoiceDisclaimerPolicy",
        featureName: "Invoice Disclaimer Policy",
        mainText: "Set your own customized disclaimer for your GST invoice",
        toShowBorder: true,
      },
      {
        key: "invoiceDisclaimerPolicy",
        featureName: "Invoice Disclaimer Policy",
        mainText: "Set your own customized disclaimer for your GST invoice",
        toShowBorder: false,
      },
    ],
  },
  {
    type: "General policies",
    settingList: [
      {
        key: "termsAndCondition",
        featureName: "Terms & conditions",
        mainText: "",
        toShowBorder: true,
      },
      {
        key: "privacyPolicy",
        featureName: "Privacy policy",
        mainText: "",
        toShowBorder: false,
      },
    ],
  },
];
export const SERVICE_STORE_POLICY_SETTING = [
  {
    type: "Order policies",
    settingList: [
      {
        key: "cancelAndRefund",
        featureName: "Booking cancellation & rejection policy",
        mainText: "Set a policy for booking cancellations & rejections",
        toShowBorder: true,
      },
      {
        key: "shippingPaymentPolicy",
        featureName: "Shipping & payment policy",
        mainText: "Set a policy for order shipping & payment",
        toShowBorder: true,
      },
      {
        key: "invoiceDisclaimerPolicy",
        featureName: "Invoice Disclaimer Policy",
        mainText: "Set your own customized disclaimer for your GST invoice",
        toShowBorder: true,
      },
    ],
  },
  {
    type: "General policies",
    settingList: [
      {
        key: "termsAndCondition",
        featureName: "Terms & conditions",
        mainText: "",
        toShowBorder: true,
      },
      {
        key: "privacyPolicy",
        featureName: "Privacy policy",
        mainText: "",
        toShowBorder: false,
      },
    ],
  },
];

export const OTHER_PAGE_SETTING = [
  {
    type: "",
    settingList: [
      {
        key: "aboutUs",
        featureName: "About us",
        mainText: "",
        toShowBorder: true,
      },
      {
        key: "privacyPolicy",
        featureName: "Privacy policy",
        mainText: "",
        toShowBorder: true,
      },
      {
        key: "termsAndCondition",
        featureName: "Terms & conditions",
        mainText: "",
        toShowBorder: false,
      },
    ],
  },
  {
    type: "",
    settingList: [
      {
        key: "logoutFromAllDevices",
        featureName: "Log out from all devices",
        mainText: "",
        toShowBorder: true,
      },
      {
        key: "logOut",
        featureName: "Log out",
        mainText: "",
        toShowBorder: false,
      },
    ],
  },
  {
    type: "",
    settingList: [
      {
        key: "deleteAccount",
        featureName: "Delete account",
        mainText: "",
        toShowBorder: false,
      },
    ],
  },
];

export const TAB_COLOR = {
  INACTIVE_TAB_COLOR: "#222222",
  ACTIVE_TAB_COLOR: "#2B74D8",
};

export const ACTIVE_SIDEBAR_INITIAL_STATE = {
  id: "store",
  type: "STORE",
  option: {
    logo: (color) => ControlSVG({ color: color }),
    settingType: "Controls",
    subSettings: null,
    isActive: true,
    route: SETTING_PAGE_SUBROUTES.CONTROLS,
  },
};

export const STORE_SETTINGS_UPDATE_SUCCESS = "Store Settings Successfully Updated";
export const PLACEHOLDER_TEXT = {
  PHONE: "Add number",
  SHOP_DESCRIPTION: "Add shop description",
  SHOP_ADDRESS: "Add shop address",
  SHOP_EMAIL: "Add your email",
  SHOP_BUSINESS: "Add business type",
  SOCIAL_MEDIA: "Add your social media links",
  ADD_KYC: "Verify your store with KYC",
  ADD_GST: "Add GST (Optional)",
  ADD_BANK_ACCOUNT: "Add a bank account to receive payments",
  MANAGE_INVOICES: "Manage invoices for your subscriptions",
  SERVICE_BASED_CHANGE_THEME: "Select a service based theme for your store from our theme collection",
  SERVICE_BASED_EDIT_BUTTON: "Edit the text of buttons of your website according to your requirements",
};

export const EDIT_BUTTON_ERROR_TEXT = "Kindly change to appropriate theme before editing text.";
export const SHOP_NAME_ERROR_BUTTON = "Shop Name can not be empty!";
export const INVALID_MOBILE_NO = "Invalid Display Number!";
export const EXISING_DISPLAY_NUMBER = "This number is already your display number.";
export const INVALID_SHOP_NAME = "Invalid Shop Name!";
export const EDIT_BUTTON_QUERY_PARAM = "?edit_button_text=true";
export const LOGO_UPLOAD_ERROR = "Unable to upload logo!";

export const COMMON_KEYS = {
  NAME: "name",
  ADDRESS: "address",
  PREPAID_ORDER_CONSTANTS: "prepaidOrders",
  B2C_ORDER_TYPE: "Pay on Delivery / Pickup",
  SERVICE_ORDER_TYPE: "Pay after service",
  PREPAID_ORDER_TYPE: "Prepaid Orders",
  GENERAL_POLICIES: "General policies",
  HELP_CENTER: "Help center",
  CHANGE_THEME: "Change theme",
  EDIT_BUTTON_TEXT: "Edit button text",
  SHIPPING: "Shipping",
  LEAD_GENERATION: "Lead Generation",
  DISPLAY_NUMBER: "displayNumber",
  DISPLAY_OTP: "displayOtp",
  DELIVERY_PARTNER: "deliveryPartner",
  GENERAL: "GENERAL",
  STAFF: "Staff",
  STORE_FLAG: "store_flag",
  DELIVERY_FLAG: "delivery_flag",
  PICKUP_FLAG: "pickup_flag",
  OTP_ENTERED: "otpEntered",
  PAYMENT_MODE: "payment-mode",
};

export const STORE_POLICY_PAGE_TYPE = {
  TERMS_CONDITION: 1,
  PRIVACY_POLICY: 3,
  SHIPMENT: 4,
};

export const CLEVERTAP_EVENTS = {
  PAYMENT_MODE: "Payment_Modes",
  GST_BILLING: "GST_Billing",
  PREPAID_ORDERS: "Prepaid_Orders",
  SALES_EVENT: "Sales_Related_Events",
};
export const SUBSCRPTION_TYPE = {
  PREPAID_ORDERS: "prepaidOrders",
  PAYMENT_OPTIONS: "paymentOptions",
};
export const QUOTES = "Quotes";
export const BUSINESS = "BUSINESS";
export const ECOMMERCE = "E-COMMERCE";
export const SERVICE = "SERVICE";
export const PAGE_TITLE = {
  STORE_CONTROLS: "Store controls",
  PROFILE_SETTINGS: "Profile settings",
  ORDER_SETTINGS: "Order settings",
  QUOTES_SETTINGS: "Quote settings",
  CUSTOMER_SETTINGS: "Customer settings",
  STAFF_SETTINGS: "Staff settings",
  STORE_POLICIES: "Store policies",
  OTHER_SETTINGS: "Other settings",
};

export const THEME_TYPE = {
  CUSTOME_THEME: "custom_theme_pd",
};

export const ACTION_TEXT = {
  ADD: "Add",
  EDIT: "Edit",
  CHANGE: "Change",
  SELECT: "Select",
  ADD_LINKS: "Add Links",
};
export const ORDER_PAYMENT_TYPE_TEXT = {
  SWITCH_TO_PREPAID_TEXT: "Switch on prepaid payment modes to set charges",
  SWITCH_TO_COD: "Switch on Cash on Delivery to set charges",
};
export const ACTIONS = {
  PREPAID_ORDERS: "prepaidOrders",
  PAYMENT_OPTIONS: "paymentOptions",
};

export const REGEX = {
  NUMBER_REGEX: /[^0-9]/g,
  MOBILE_REGEX: /^[0-9]{10}$/,
};

export const LEAD_GENERATION_CARD = {
  HEADING: "Get customer leads",
  DESCRIPTION: "Get leads with subscribe now popup",
};

export const STORE_CONROL_BUTONS = {
  store_flag: {
    buttonName: "Store status",
    key: "store_flag",
  },
  delivery_flag: {
    buttonName: "Delivery status",
    key: "delivery_flag",
  },
  pickup_flag: {
    buttonName: "Pick up status",
    key: "pickup_flag",
  },
};

export const KYC_STATUS_KEY = {
  NOT_VERIFIED: 0,
  PENDING: 1,
  VERIFICATION_FAILED: 2,
  VERIFIED: 3,
};

export const LEAD_GENERATION_IMAGE = "https://cdn.dotpe.in/longtail/custom_website/7612844/WuYMw1Ap.png";
export const STATUS_KEY = {
  ZERO: 0,
  ONE: 1,
};
export const FEATURE_NAME = {
  DELIVERY_PARTNER: "Delivery Partners",
};

export const SLIDES_VISIBLE = 3;

export const CHARACTER_LIMIT = {
  COLLECTION_NAME: 40,
  CATEGORY_NAME: 80,
};
