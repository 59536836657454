import { lazy, Suspense } from "react";
import { PageLoader } from "./Components";
import history from "./Utils/history";
import { Route, Router, Switch } from "react-router-dom";
import {
  BUY_SUBSCRIPTION,
  CRM_ROUTE,
  FACEBOOK_BUSINESS_SUITE_ROUTE,
  WORKSPACE_POSTPURCHASE_ROUTE,
  WORKSPACE_PREPURCHASE_ROUTE,
  LEAD_GENERATION_ROUTE,
  SETTINGS_STORE_POLICY,
  SETTINGS_ROUTE,
  STORE_ANALYTICS_ROUTE,
  GOOGLE_SHOPPING_ROUTE,
  MARKETING_ROUTE,
  FACEBOOK_ADS,
  SEO_TOOLS,
  SMS_MARKETING_ROUTE,
  CREATE_CAMPAIGN_ROUTE,
  MOBILE_ONBOARDING,
  SMS_CAMPAIGN_PAYMENT_SUCCESS_ROUTE,
  LOGIN_PAGE,
  COLLECTIONS_LIST,
  ADD_COLLECTION,
  EDIT_COLLECTION,
  COLLECTION_CATEGORY_LIST,
  CUSTOM_DELIVERY_SETUP,
  CUSTOM_DELIVERY_DETAILS,
  CUSTOM_ZONES_SETUP,
  GST_BILLING_ROUTE,
  WORKSPACE_ACCOUNT_SETUP,
  WORKSPACE_ACCOUNT_INITIAL_SETUP,
  CUSTOMER_REVIEW_RATINGS_ROUTE,
  WHATSAPP_BUSINESS_DEMO,
  OUT_OF_STOCK_ROUTE,
  SETUP_B2B_ROUTE,
  GOOGLE_SHOPPING_V2_LANDING_PAGE,
  GOOGLE_SHOPPING_V2_SUCCESS_PAGE,
  GOOGLE_SHOPPING_V2_MERCHANT_FORM,
  GOOGLE_SHOPPING_V2_DASHBOARD,
  BULK_EDIT,
  SUBSCRIPTION_PAYMENT_USING_COUPONS_ROUTE,
  SUBMIT_SUGGESTION_ROUTE,
  ORDER_CANCELLATION_REFUND_ROUTE,
  CUSTOMER_ADDRESS_FIELD,
  CONTACT_US,
  BRAND_WEBSITE_BUSINESS_CATEGORIES,
  BRAND_WEBSITE_CATEGORY_THEMES,
  BRAND_WEBSITE_THEME_DEMO,
  BRAND_WEBSITE_THEME_PREVIEW,
  BRAND_WEBSITE_PAYMENT_SUCCESS,
  DOMAIN_CART_PAGE,
  DOMAIN_PURCHASE_SUCCESS,
  PREMIUM_PAGE,
  SUBSCRIPTION_STATUS_DETAILS,
  DOMAIN_LANDING_PAGE_PREFIX,
  EXIT_GATING_HOMEPAGE,
  EXIT_GATING_EDITOR,
  MY_INVOICES_ROUTE,
  SOCIAL_MEDIA_PROFILE_ROUTE,
  SHIPPING_LABEL_GENERATOR,
  TITAN_DASHBOARD,
  APP_UTILITIES,
  REDIRECTION_CONNECTOR,
  MARKETING_COUPONS_LIST,
  BRAND_WEBSITE_RENEWAL_PAGE,
  INVOICE_DISCLAIMER_POLICY_ROUTE,
} from "./Constants/routesConstant";
import WebViewRouter from "./routesWebview";
import withLazyComponent from "./Utils/DashyHoc";
import useActiveSubscription from "./Hooks/useActiveSubscription";
import { SETTING_PAGE_SUBROUTES } from "./Constants/setting";
/** Components */
const ContactUs = lazy(() => import("./Containers/ContactUs"));
const EmailVerify = lazy(() => import("./Containers/EmailVerify"));
const BrandLeads = lazy(() => import("./Containers/BrandLeads"));
const ThemeExplore = lazy(() => import("./Containers/ThemeExplore"));
const Orders = lazy(() => import("./Containers/Orders"));
const OrdersV2 = lazy(() => import("./Containers/OrdersV2"));
const OrderDetail = lazy(() => import("./Containers/OrderDetail"));
const Catalog = lazy(() => import("./Containers/Catalog"));
const Settings = lazy(() => import("./Containers/Settings"));
// const EditBussiness = lazy(() => import("./Containers/EditBusiness"));
const AppSettingsComponent = lazy(() => import("./Containers/AppSettings"));
const Marketing = lazy(() => import("./Containers/Marketing"));
const CollectionsList = lazy(() => import("./Containers/CollectionsList"));
const CreateCollection = lazy(() => import("./Containers/CreateAndEditCollectionComponents"));
const EditCollection = lazy(() => import("./Containers/CreateAndEditCollectionComponents"));
const Onboarding = lazy(() => import("./Containers/Onboarding"));
const ThemePage = lazy(() => import("./Containers/ThemePage"));
const ThemeList = lazy(() => import("./Containers/ThemeList"));
const ThemePreview = lazy(() => import("./Containers/ThemePreview"));
const Payment = lazy(() => import("./Containers/Payment"));
const ThemeLoader = lazy(() => import("./Containers/ThemeLoader"));
const ThemeHistory = lazy(() => import("./Containers/ThemeHistory"));
const ThemeEdit = lazy(() => import("./Containers/ThemeEdit"));
const ThemeImageCrop = lazy(() => import("./Containers/ThemeImageCrop"));
const DomainExplore = lazy(() => import("./Containers/DomainExplore"));
const DomainSearch = lazy(() => import("./Containers/DomainSearch"));
const DomainPurchaseSuccess = lazy(
  () => import("./Containers/DomainSalesSearch/components/DomainPurchaseSuccess/DomainPurchaseSuccess"),
);
const DomainSuccess = lazy(() => import("./Containers/DomainSuccess"));
const DomainHistory = lazy(() => import("./Containers/DomainHistory"));
const DomainDetails = lazy(() => import("./Containers/DomainDetails"));
const NotFound = lazy(() => import("./Containers/404"));
const PaymentProcessing = lazy(() => import("./Containers/PaymentProcessing"));
const DomainConnectSuccess = lazy(() => import("./Containers/DomainConnectSuccess"));
const PaymentOptions = lazy(() => import("./Components/PaymentOptions"));
const StoreControls = lazy(() => import("./Containers/StoreControls"));
const CreateBill = lazy(() => import("./Containers/CreateBill"));
const MyQrCode = lazy(() => import("./Containers/MyQrCode"));
const MyPayments = lazy(() => import("./Containers/MyPayments"));
const GoogleAdsDiscover = lazy(() => import("./Containers/GoogleAdsDiscover"));
const GoogleAdManager = lazy(() => import("./Containers/GoogleAdManager"));
const GoogleAdDetails = lazy(() => import("./Containers/GoogleAdDetails"));
const GoogleAdsHistory = lazy(() => import("./Containers/GoogleAdsHistory"));
const CouponsList = lazy(() => import("./Containers/CouponsList"));
const CouponsVouchers = lazy(() => import("./Containers/CouponsVouchers"));
const CreateCoupon = lazy(() => import("./Containers/CreateCoupon"));
const PaymentLink = lazy(() => import("./Components/PaymentLinkFlow"));
const MarketingAnalytics = lazy(() => import("./Containers/MarketingTags"));
const Subscription = lazy(() => import("./Containers/Subscription"));
const SubmitSuggestion = lazy(() => import("./Containers/SubmitSuggestion"));
const MyInvoices = lazy(() => import("./Containers/MyInvoices"));
const BuySubscription = lazy(() => import("./Containers/SubscriptionLanding"));
// const PremiumSubscription = lazy(() => import("./Components/SubscriptionLayout/PremiumSubscription"));
const SubscriptionDetails = lazy(() => import("./Containers/SubscriptionDetails"));
const DeliveryPartnerPage = lazy(() => import("./Containers/DeliveryPartnerPage"));
const DeliveryPartner = lazy(() => import("./Containers/DeliveryPartner"));
const ReturnRefundPolicyPage = lazy(() => import("./Containers/ReturnRefundPolicyPage"));
const InvoiceDisclaimerPolicyPage = lazy(() => import("./Containers/InvoiceDisclaimerPolicyPage"));
const kycPage = lazy(() => import("./Containers/KycCompletionPage"));
const EditPageComponent = lazy(() => import("./Containers/EditPageComponent"));
const AdditionalPages = lazy(() => import("./Containers/AdditionalPages"));
const Carts = lazy(() => import("./Containers/Carts"));
const UnstructuredEditor = lazy(() => import("./Containers/UnstructuredEditor"));
const StaffManagement = lazy(() => import("./Containers/Staffmanagement"));
const GoogleWorkspacePrePurchase = lazy(() => import("./Containers/GoogleWorkspace/PrePurchase"));
const GoogleWorkspacePostPurchase = lazy(() => import("./Containers/GoogleWorkspace/PostPurchase"));
const WorkspaceAccountSetup = lazy(() => import("./Containers/GoogleWorkspace/WorkspaceAccountSetup/index"));
const WorkspaceAccountSetupAfterInitialSetup = lazy(
  () => import("./Containers/GoogleWorkspace/WorkspaceAccountSetupAfterInitialSetup/index"),
);
const CRM = lazy(() => import("./Containers/CustomerRelationManagement"));
const ProductCartDetails = lazy(() => import("./Containers/Carts/ProductCartDetails"));
const FacebookBusinessSuite = lazy(() => import("./Containers/FacebookBusinessSuite"));
const MyCreditsComponent = lazy(() => import("./Containers/MyCredits"));
const LeadGeneration = lazy(() => import("./Containers/LeadGeneration"));
const StorePolicy = lazy(() => import("./Containers/StorePolicy"));
const StorePolicyEditor = lazy(() => import("./Containers/StorePolicy/PolicyEditor"));
const StoreAnalytics = lazy(() => import("./Containers/Analytics/StoreAnalytics"));
const GoogleShoppingComponent = lazy(() => import("./Containers/GoogleShopping"));
const FacebookAds = lazy(() => import("./Containers/FacebookAds"));
const SEOTools = lazy(() => import("./Containers/SEOTools"));
const SmsMarketing = lazy(() => import("./Containers/SmsMarketing"));
const CreateCampaign = lazy(() => import("./Containers/CreateCampaign"));
const MobileOnboarding = lazy(() => import("./Containers/MobileOnboarding"));
const CampaignPaymentSuccess = lazy(() => import("./Components/SmsCampaign/campaignPaymentSuccess"));
const CollectionCategoryList = lazy(() => import("./Containers/CollectionCategoryList"));
const CustomDeliverySetup = lazy(() => import("./Containers/CustomDeliverySetup"));
const CustomDeliveryDetails = lazy(() => import("./Containers/CustomDeliveryDetails"));
const CustomZoneDetails = lazy(() => import("./Containers/CustomZoneSetup"));
const GSTBilling = lazy(() => import("./Containers/GSTBilling"));
const AdvCatalog = lazy(() => import("./Containers/AdvCatalog"));
const CustomerReviewRatings = lazy(() => import("./Containers/CustomerReviewRatings"));
const ManageDNSSettings = lazy(() => import("./Containers/ManageDNS"));
const SocialMediaProfiles = lazy(() => import("./Containers/SocialMediaProfiles"));
const WhatsappMarketingLanding = lazy(() =>
  import("./Containers/WhatsappMarketingLanding").then((module) => ({ default: module.WhatsappMarketingLanding })),
);
const OrderCancellationRefund = lazy(() => import("./Containers/OrderCancellationRefund"));
const WidgetPageBuilder = lazy(() => import("./Containers/WidgetPageBuilder"));
const B2B = lazy(() => import("./Containers/B2B").then((module) => ({ default: module.B2B })));
const SubscriptionPurchaseUsingCoupon = lazy(() => import("./Containers/SubscriptionPurchaseUsingCoupon"));
const OutOfStockQuery = lazy(() => import("./Containers/NotifyProduct"));
const GshopV2LandingPage = withLazyComponent(
  () => import("./Containers/GoogleShoppingV2/Containers/GshopLandingPage/GshopLandingPage"),
);
const GshopMerchantForm = withLazyComponent(
  () => import("./Containers/GoogleShoppingV2/Containers/GshopFormPage/GshopFormPage"),
);
const GshopSuccessPageV2 = withLazyComponent(
  () => import("./Containers/GoogleShoppingV2/Containers/GshopSuccessSetup/GshopSuccessSetup"),
);
const GshopDashboard = withLazyComponent(
  () => import("./Containers/GoogleShoppingV2/Containers/GshopDashboard/GshopDashboard"),
);
const BulkEdit = withLazyComponent(() => import("./Containers/BulkEdit/Containers/BulkEdit/BulkEdit"));
const DomainSalesLanding = lazy(() => import("./Containers/DomainSalesLanding/index"));
const DomainSalesCompletion = lazy(() => import("./Containers/DomainSalesCompletion/index"));
const DomainSalesSearch = lazy(() => import("./Containers/DomainSalesSearch/index"));
const CustomerAddressFields = lazy(() => import("./Containers/CustomerAddressFields/index"));
const BrandWebsiteCategories = lazy(() => import("./Containers/BrandWebsiteCategories"));
const BrandWebsiteCategoryThemes = lazy(() => import("./Containers/BrandWebsiteCategoryThemes"));
const BrandWebsiteThemePreview = lazy(() => import("./Containers/BrandWebsiteThemePreview"));
const BrandWebsiteDemo = lazy(() => import("./Containers/BrandWebsiteDemo"));
const BrandWebsitePaymentSuccess = lazy(() => import("./Containers/BrandWebsitePaymentSuccessful"));
const BrandWebsiteRenewal = lazy(() => import("./Containers/BrandWebsiteRenewalPage"));
const DomainCartPage = lazy(() => import("./Containers/DomainSalesSearch/components/CartPage"));
const PremiumPage = lazy(() => import("./Containers/NewPremiumPage/Containers/PremiumHomePage/PremiumHomePage"));
const UtilitiesPage = lazy(() => import("./Containers/AppUtilities/dashyUtilities"));
const SubscriptionStatusDetails = lazy(
  () => import("./Containers/NewPremiumPage/Containers/SubscriptionStatusDetails/SubscriptionStatusDetails"),
);
const DomainLandingPage = lazy(() => import("./Containers/DomainLandingPage"));
const ExitGatingHomePage = lazy(() => import("./Containers/ExitGatingHomePage"));
const ExitGatingEditorPage = lazy(() => import("./Containers/ExitGatingEditorPage"));
const GenerateShippingLabel = lazy(() => import("./Containers/GenerateShippingLabel"));
const TitanDashboard = lazy(() => import("./Containers/TitanEmailDashboard"));
const RedirectionConnector = lazy(() => import("./Containers/RedirectionConnector"));

const AppRouter = (): JSX.Element => {
  const { isBrandWebsiteThemePurchased } = useActiveSubscription();

  return (
    <Router history={history}>
      <Suspense fallback={<PageLoader show={true} />}>
        <Switch>
          <Route path="/webview">
            <WebViewRouter />
          </Route>
          <Route exact path={MOBILE_ONBOARDING} component={MobileOnboarding} />
          <Route exact path="/email-verification" component={EmailVerify} />
          <Route exact path="/orders" component={OrdersV2} />
          <Route exact path="/ordersv2" component={Orders} />
          <Route exact path="/leads" component={isBrandWebsiteThemePurchased ? BrandLeads : Carts} />
          <Route exact path="/cart-product-details/:id/:updated_at" component={ProductCartDetails} />
          <Route exact path="/order-detail/:hash" component={OrderDetail} />
          <Route exact path="/catalog" component={Catalog} />
          <Route path={SETTINGS_ROUTE} component={Settings} />
          <Route exact path={SOCIAL_MEDIA_PROFILE_ROUTE} component={SocialMediaProfiles} />
          <Route exact path="/settings/app-settings" component={AppSettingsComponent} />
          <Route exact path="/marketing" component={Marketing} />
          <Route exact path={LOGIN_PAGE} component={Onboarding} />
          <Route exact path="/" component={Onboarding} />
          <Route exact path="/theme" component={ThemePage} />
          <Route exact path="/themes-explore" component={ThemeExplore} />
          <Route exact path="/themes/category/:catid" component={ThemeList} />
          <Route exact path="/themes/color/:colorid" component={ThemeList} />
          <Route exact path="/themes-preview" component={ThemePreview} />
          <Route exact path="/payment" component={Payment} />
          <Route exact path="/themes-loader" component={ThemeLoader} />
          <Route exact path="/themes-history" component={ThemeHistory} />
          <Route exact path="/web-editor" component={ThemeEdit} />
          <Route exact path="/themes-image-crop" component={ThemeImageCrop} />
          <Route exact path="/payment/processing/:txnId" component={PaymentProcessing} />
          <Route exact path="/domain-explore" component={DomainExplore} />
          <Route exact path="/domain-search" component={DomainSearch} />
          <Route exact path="/domain-success" component={DomainSuccess} />
          <Route exact path="/domain-history" component={DomainHistory} />
          <Route exact path="/domain-details" component={DomainDetails} />
          <Route exact path="/domain-connect-success" component={DomainConnectSuccess} />
          <Route exact path="/payment-options" component={PaymentOptions} />
          <Route exact path="/create-bill" component={CreateBill} />
          <Route exact path="/payment-link" component={PaymentLink} />
          <Route exact path="/marketing/my-qr-code" component={MyQrCode} />
          <Route exact path="/my-payments" component={MyPayments} />
          <Route exact path="/marketing/ads-discover" component={GoogleAdsDiscover} />
          <Route exact path="/marketing/ad/:adId/step/:stepNumber" component={GoogleAdManager} />
          <Route exact path="/marketing/ad-details/:adId" component={GoogleAdDetails} />
          <Route exact path="/marketing/ads-history/:activeTabIndex" component={GoogleAdsHistory} />
          {/* TODO: fix routing before moving to prod */}
          {/* <Route exact path="/marketing/coupons-list" component={CouponsList} /> */}
          <Route exact path={MARKETING_COUPONS_LIST} component={CouponsVouchers} />
          {/* <Route exact path="/marketing/coupons-vouchers" component={CouponsVouchers} /> */}
          <Route exact path="/marketing/create-coupon" component={CreateCoupon} />
          <Route exact path={COLLECTIONS_LIST} component={CollectionsList} />
          <Route exact path={ADD_COLLECTION} component={CreateCollection} />
          <Route exact path={EDIT_COLLECTION} component={EditCollection} />
          <Route exact path={COLLECTION_CATEGORY_LIST} component={CollectionCategoryList} />
          <Route exact path={GST_BILLING_ROUTE} component={GSTBilling} />
          <Route exact path={SUBMIT_SUGGESTION_ROUTE} component={SubmitSuggestion} />
          <Route exact path={ORDER_CANCELLATION_REFUND_ROUTE} component={OrderCancellationRefund} />
          <Route exact path={CUSTOMER_REVIEW_RATINGS_ROUTE} component={CustomerReviewRatings} />
          <Route exact path="/marketing/analytics/:type" component={MarketingAnalytics} />
          <Route exact path="/delivery-partner" component={DeliveryPartnerPage} />
          <Route exact path="/delivery-partner/:partnerName" component={DeliveryPartner} />
          <Route exact path="/return-refund-policy" component={ReturnRefundPolicyPage} />
          <Route exact path={INVOICE_DISCLAIMER_POLICY_ROUTE} component={InvoiceDisclaimerPolicyPage} />
          <Route exact path="/setup-delivery" component={kycPage} />
          {/* Subscription */}
          <Route exact path="/subscription" component={Subscription} />
          <Route exact path="/subscription-details" component={SubscriptionDetails} />
          <Route exact path={BUY_SUBSCRIPTION} component={BuySubscription} />
          <Route exact path="/edit-page/:pid" component={EditPageComponent} />
          <Route exact path="/additional-pages" component={AdditionalPages} />
          <Route exact path="/additional-pages/editor/:pid" component={UnstructuredEditor} />
          <Route exact path="/staff-details" component={StaffManagement} />
          <Route exact path="/my-credits" component={MyCreditsComponent} />
          <Route exact path={WORKSPACE_PREPURCHASE_ROUTE} component={GoogleWorkspacePrePurchase} />
          <Route exact path={WORKSPACE_POSTPURCHASE_ROUTE} component={GoogleWorkspacePostPurchase} />
          <Route exact path={WORKSPACE_ACCOUNT_SETUP} component={WorkspaceAccountSetupAfterInitialSetup} />
          <Route exact path={WORKSPACE_ACCOUNT_INITIAL_SETUP} component={WorkspaceAccountSetup} />
          <Route exact path={CRM_ROUTE} component={CRM} />
          <Route exact path={FACEBOOK_BUSINESS_SUITE_ROUTE} component={FacebookBusinessSuite} />
          <Route exact path={LEAD_GENERATION_ROUTE} component={LeadGeneration} />
          <Route exact path={SETTING_PAGE_SUBROUTES.STORE_POLICIES} component={StorePolicy} />
          <Route exact path={CONTACT_US} component={ContactUs} />
          <Route exact path={`${SETTINGS_STORE_POLICY}/:id`} component={StorePolicyEditor} />
          <Route exact path={STORE_ANALYTICS_ROUTE} component={StoreAnalytics} />
          <Route exact path={MARKETING_ROUTE + GOOGLE_SHOPPING_ROUTE} component={GoogleShoppingComponent} />
          <Route exact path={MARKETING_ROUTE + FACEBOOK_ADS} component={FacebookAds} />
          <Route exact path={MARKETING_ROUTE + SEO_TOOLS} component={SEOTools} />
          <Route exact path={SMS_MARKETING_ROUTE} component={SmsMarketing} />
          <Route exact path={MARKETING_ROUTE + CREATE_CAMPAIGN_ROUTE} component={CreateCampaign} />
          <Route exact path={SMS_CAMPAIGN_PAYMENT_SUCCESS_ROUTE} component={CampaignPaymentSuccess} />
          <Route exact path={CUSTOM_DELIVERY_SETUP + "/:itemId?"} component={CustomDeliverySetup} />
          <Route exact path={CUSTOM_DELIVERY_DETAILS} component={CustomDeliveryDetails} />
          <Route exact path={CUSTOM_ZONES_SETUP + "/:itemId?"} component={CustomZoneDetails} />
          {/* <Route exact path="/kinetic-ui" component={Sample} /> */}
          <Route exact path="/catalog/:id" component={AdvCatalog} />
          <Route exact path={WHATSAPP_BUSINESS_DEMO} component={WhatsappMarketingLanding} />
          <Route exact path={SETUP_B2B_ROUTE} component={B2B} />
          <Route exact path={OUT_OF_STOCK_ROUTE} component={OutOfStockQuery} />
          <Route exact path={GOOGLE_SHOPPING_V2_LANDING_PAGE} component={GshopV2LandingPage} />
          <Route exact path={GOOGLE_SHOPPING_V2_MERCHANT_FORM} component={GshopMerchantForm} />
          <Route exact path={GOOGLE_SHOPPING_V2_SUCCESS_PAGE} component={GshopSuccessPageV2} />
          <Route exact path={GOOGLE_SHOPPING_V2_DASHBOARD} component={GshopDashboard} />
          <Route exact path={BULK_EDIT} component={BulkEdit} />
          <Route exact path={SUBSCRIPTION_PAYMENT_USING_COUPONS_ROUTE} component={SubscriptionPurchaseUsingCoupon} />
          <Route exact path={PREMIUM_PAGE} component={PremiumPage} />
          <Route exact path={APP_UTILITIES} component={UtilitiesPage} />
          <Route exact path={SUBSCRIPTION_STATUS_DETAILS} component={SubscriptionStatusDetails} />
          <Route exact path="/page-builder" component={WidgetPageBuilder} />
          <Route exact path="/domain-sale" component={DomainSalesLanding} />
          <Route exact path="/domain-completion" component={DomainSalesCompletion} />
          <Route exact path="/domain-open-search" component={DomainSalesSearch} />
          <Route exact path={DOMAIN_PURCHASE_SUCCESS} component={DomainPurchaseSuccess} />
          <Route exact path="/manage-dns" component={ManageDNSSettings} />
          <Route exact path={CUSTOMER_ADDRESS_FIELD} component={CustomerAddressFields} />
          <Route exact path={BRAND_WEBSITE_BUSINESS_CATEGORIES} component={BrandWebsiteCategories} />
          <Route exact path={`${BRAND_WEBSITE_CATEGORY_THEMES}/:id`} component={BrandWebsiteCategoryThemes} />
          <Route exact path={`${BRAND_WEBSITE_THEME_DEMO}/:id`} component={BrandWebsiteDemo} />
          <Route exact path={`${BRAND_WEBSITE_THEME_PREVIEW}/:id`} component={BrandWebsiteThemePreview} />
          <Route exact path={BRAND_WEBSITE_PAYMENT_SUCCESS} component={BrandWebsitePaymentSuccess} />
          <Route exact path={BRAND_WEBSITE_RENEWAL_PAGE} component={BrandWebsiteRenewal} />
          <Route exact path={DOMAIN_CART_PAGE} component={DomainCartPage} />
          <Route path={DOMAIN_LANDING_PAGE_PREFIX} component={DomainLandingPage} />
          <Route exact path={`${EXIT_GATING_HOMEPAGE}`} component={ExitGatingHomePage} />
          <Route exact path={`${EXIT_GATING_EDITOR}`} component={ExitGatingEditorPage} />
          <Route exact path={`${MY_INVOICES_ROUTE}`} component={MyInvoices} />
          <Route exact path={SHIPPING_LABEL_GENERATOR} component={GenerateShippingLabel} />
          <Route exact path={TITAN_DASHBOARD} component={TitanDashboard} />
          <Route exact path={REDIRECTION_CONNECTOR} component={RedirectionConnector} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  );
};
export default AppRouter;
